import React, { useEffect, useState } from "react"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import {
  StyledProductList,
  StyledProductListItem,
} from "../components/Products/productArchive"
import PrimaryProductCard from "./Products/PrimaryProductCard"

const ProductList = ({ posts, pageType, padding, type, isAuthenticated, roles, user }) => {
  
  const [countryCode, setCountryCode] = useState("") // store token

  useEffect(() => {
    fetch('/geolocation', {
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(geoLocationData => {
      setCountryCode(geoLocationData?.geo?.country?.code)
    })
  }, [])

  return (
    <StyledProductList pageType={pageType} padding={padding}>
      {posts.map((post) => (
        !post.node.productData.hideonarchive && (
          <StyledProductListItem key={post.node.id} type={type}>
            <PrimaryProductCard
              title={post.node.title}
              id={post.node.id}
              uri={post.node.uri}
              excerpt={post.node.excerpt}
              featuredImage={post.node.featuredImage}
              pricefull={countryCode === 'US' ? post.node.productData.pricefullusd : post.node.productData.pricefull}
              pricesale={countryCode === 'US' ? post.node.productData.pricesaleusd : post.node.productData.pricesale}
              hidesale={post.node.productData.hidesale}
              amazonLink={post.node.productData.linkToAmazon}
              stripefullprice={countryCode === 'US' ? post.node.productData.stripefullpriceusd : post.node.productData.stripefullprice}
              stripesaleprice={countryCode === 'US' ? post.node.productData.stripesalepriceusd : post.node.productData.stripesaleprice}
              productTag={post.node.productsTag.nodes[0]?.slug}
              currency={countryCode === 'US' ? '$' : '£'}
              isAuthenticated={isAuthenticated}
              roles={roles}
              user={user}
            />
          </StyledProductListItem>
        )
      ))}
    </StyledProductList>
  )
}

export default ProductList
